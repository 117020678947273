import React from 'react';
import { Heading } from '../../components';

import css from './ListingPage.module.css';
import renderMarkdown from '../PageBuilder/markdownProcessor';

const SectionTextMaybe = props => {
  const { text, heading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = renderMarkdown(text);

  return text ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <div className={textClass}>{content}</div>
    </div>
  ) : null;
};

export default SectionTextMaybe;
